import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/meta/whats-new-in-swarmia.png'
import tag from '../../assets/images/tags/WNIS-1.svg'
import Layout from '../../components/Layout'
import HeroBlock from '../../components/blocks/HeroBlock'
import Block from '../../components/primitives/Block'
import { responsiveScale } from '../../styles/helpers'
import { RegistrationFormSection } from '../../components/form/WhatsNewInSwarmia'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/whats-new-in-swarmia.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
  }
`

const DevelopersPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="What’s new in Swarmia"
      variant="dark"
      isNew
      description="Thursday, April 24, 2025 — 11am ET"
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>What’s new in Swarmia</>}
        content="Join us as we share our latest product updates and an exclusive sneak peek into what we’re working on."
        backgroundImage={getImage(data.backgroundImage)!}
        primaryButton={{
          text: 'Register',
          href: '#registration',
        }}
        secondaryButton={null}
      />
      <Block paddingY={responsiveScale(64)} id="registration">
        <RegistrationFormSection />
      </Block>
      <FeaturedBlogArticles
        heading="More from the swarmia blog"
        articles={[
          '/blog/productivity-impact-of-ai-coding-tools/',
          '/blog/three-pillars-effective-software-organizations/',
          '/blog/flow-software-development/',
        ]}
        newStyles
      />
    </Layout>
  )
}

export default DevelopersPage
